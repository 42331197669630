import { CommonModule } from '@angular/common';
import { Component, ContentChild, NgModule, TemplateRef } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { NzLayoutModule } from 'ng-zorro-antd/layout';

@Component({
  selector: 'app-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['./layout.component.less']
})
export class LayoutComponent {
  @ContentChild('headerRightTemplate')
  public headerRightTemplate!: TemplateRef<HTMLElement>;
  @ContentChild('siderTemplate')
  public siderTemplate!: TemplateRef<HTMLElement>;
  @ContentChild('contentTemplate')
  public contentTemplate!: TemplateRef<HTMLElement>;

  constructor(private router: Router) {}

  public onLogoClick() {
    this.router.navigate(['']);
  }
}

@NgModule({
  declarations: [LayoutComponent],
  exports: [LayoutComponent],
  imports: [NzLayoutModule, RouterModule, CommonModule]
})
export class LayoutModule {}
