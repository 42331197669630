<nz-layout class="layout">
  <nz-header>
    <div class="layout__logo">
      <img
        class="clickable"
        [src]="'assets/images/logo.png'"
        alt="Videobomb"
        (click)="onLogoClick()"
      />
    </div>

    <div class="layout__right">
      <ng-container *ngTemplateOutlet="headerRightTemplate"></ng-container>
    </div>
  </nz-header>

  <nz-layout>
    <nz-sider
      *ngIf="siderTemplate"
      class="layout-sider"
      nzWidth="360px"
    >
      <ng-container *ngTemplateOutlet="siderTemplate"></ng-container>
    </nz-sider>

    <nz-content>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </nz-content>
  </nz-layout>
</nz-layout>
